<template>
  <admin-container>
    <div class="relative md:w-[70%] lg:w-[50%]">
      <h1 class="text-3xl font-bold mb-8">Цэс нэмэх</h1>
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-700"
          >Салбар сонгох</label
        >
        <select
          name="branch"
          id="branch"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.branch"
          @change="handleFormInput"
        >
          <option value="" disabled selected>сонгох</option>
          <option :value="1">Gate 4</option>
          <option :value="2">Gate 5</option>
          <option :value="3">Gate 6</option>
          <option :value="4">Gate 7</option>
          <option :value="5">Billiard</option>
          <option :value="6">Karaoke</option>
        </select>
      </div>
      <div v-if="form.branch" class="mb-4">
        <label for="category" class="block text-sm font-medium text-gray-700"
          >Категори</label
        >
        <select
          name="category"
          id="category"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.parentCategory"
          @change="handleCategory"
        >
          <option value="" disabled selected>сонгох</option>
          <option
            v-for="cat in categories"
            :value="cat.id"
            :key="cat.id"
            class="uppercase"
          >
            {{ cat.name }}
          </option>
        </select>
      </div>
      <div v-if="form.parentCategory" class="mb-4">
        <label for="subCategory" class="block text-sm font-medium text-gray-700"
          >Дэд категори</label
        >
        <select
          name="subCategory"
          id="subCategory"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.subCategory"
          @change="handleFormInput"
        >
          <option value="" disabled selected>сонгох</option>
          <option
            v-for="subCat in subCategories"
            :value="subCat.id"
            :key="subCat.id"
            class="uppercase"
          >
            {{ subCat.name }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-700"
          >Нэр</label
        >
        <input
          type="text"
          id="name"
          name="name"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.name"
          @change="handleFormInput"
        />
      </div>

      <div class="mb-4">
        <label for="price" class="block text-sm font-medium text-gray-700"
          >Үнэ</label
        >
        <!-- set limit for price shouldnot be below 0 -->
        <input
          type="number"
          id="price"
          name="price"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.price"
          min="0"
          @change="handleFormInput"
        />
      </div>

      <div
        v-if="!thumbnailUrl"
        class="w-52 h-52 bg-gray-200 p-2 mb-4 text-sm flex flex-col gap-4 items-center justify-center cursor-pointer border border-gray-300 rounded hover:border-blue-900 hover:text-blue-900"
        @click="triggerFileInput"
      >
        <img
          src="../../assets/svg/add.svg"
          alt="upload"
          class="h-28 w-28 object-fit"
        />
        <span class="text-blue-900 text-sm font-bold">Нүүр зураг оруулах</span>
        <input
          id="thumbnail"
          type="file"
          class="hidden"
          ref="fileInput"
          @change="handleThumbnail"
        />
      </div>

      <div
        v-else
        class="relative w-52 h-52 bg-gray-200 p-2 mb-4 text-sm flex flex-col gap-4 items-center justify-center cursor-pointer border border-gray-300 rounded"
      >
        <div class="absolute top-0 right-0">
          <button
            class="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            @click="thumbnailUrl = ''"
          >
            X
          </button>
        </div>
        <img :src="thumbnailUrl" alt="upload" class="h-72 w-72 object-fit" />
        <input
          type="file"
          class="hidden"
          ref="fileInput"
          @change="handleThumbnail"
        />
      </div>

      <div class="mb-4">
        <label for="description" class="block text-sm font-medium text-gray-700"
          >Тайлбар</label
        >
        <textarea
          id="description"
          name="description"
          class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
          :value="form.description"
          @change="handleFormInput"
        ></textarea>
      </div>
      <button
        type="submit"
        class="flex justify-center items-center bg-brand text-white p-2 rounded-md w-52 hover:opacity-90 transition duration-200 ease-in-out"
        @click="handleSubmit"
      >
        <!-- loadin animation -->
        <svg
          v-if="isLoading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
        Нэмэх
      </button>
    </div>
    <error-modal v-if="error" :error="error" />
    <success-modal v-if="success" :success="success" />
  </admin-container>
</template>

<script>
import { ref, reactive, watch } from "vue";
import ErrorModal from "../../components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
import adminInstance from "../../lib/api/adminInstance";
export default {
  name: "AddCulinary",
  components: {
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const form = reactive({
      branch: "",
      name: "",
      parentCategory: "",
      subCategory: "",
      price: "",
      image: "",
      description: "",
    });

    const categories = ref([]);
    const subCategories = ref([]);

    const thumbnailUrl = ref("");
    const fileInput = ref(null);

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const fetchCategories = async () => {
      try {
        const res = await adminInstance.get(`/category`);
        if (res.status === 200 && form.branch) {
          categories.value = res.data.category.filter(
            (cat) => cat.branch_id === parseInt(form.branch)
          );
        }
      } catch (err) {
        error.value = err.response.data.message;
        resetMessage();
      }
    };

    const handleCategory = (e) => {
      form.parentCategory = e.target.value;
      form.subCategory = "";
      subCategories.value = categories.value.filter(
        (cat) => cat.id === parseInt(form.parentCategory)
      )[0].subCategory;
    };

    watch(
      () => form.branch,
      () => {
        fetchCategories();
      }
    );

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const handleFormInput = (e) => {
      form[e.target.name] = e.target.value;
    };

    const handleThumbnail = (e) => {
      form.image = e.target.files[0];
      thumbnailUrl.value = URL.createObjectURL(form.image);
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    const errors = [
      { name: "branch", message: "Салбар сонгоно уу" },
      { name: "name", message: "Нэр оруулна уу" },
      { name: "parentCategory", message: "Категори сонгоно уу" },
      { name: "price", message: "Үнэ оруулна уу" },
      { name: "image", message: "Зураг оруулна уу" },
      { name: "description", message: "Тайлбар оруулна уу" },
    ];

    const validateForm = () => {
      for (let i = 0; i < errors.length; i++) {
        if (!form[errors[i].name]) {
          error.value = errors[i].message;
          resetMessage();
          return false;
        }
        if (form.price < 0) {
          error.value = "Үнэ 0-ээс бага байж болохгүй";
          resetMessage();
          return false;
        }
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validateForm()) return;
      const formData = new FormData();
      formData.append("branch", form.branch);
      formData.append("name", form.name);
      formData.append("parentCategory", form.parentCategory);
      formData.append("subCategory", form.subCategory);
      formData.append("price", form.price);
      formData.append("image", form.image);
      formData.append("description", form.description);
      try {
        isLoading.value = true;
        const res = await adminInstance.post("/culinary", formData);
        isLoading.value = false;
        if (res.status === 200) {
          success.value = res.data.message;
          resetMessage();
        }
        form.name = "";
        // form.branch = "";
        // form.parentCategory = "";
        form.price = "";
        form.image = "";
        form.description = "";
        thumbnailUrl.value = "";
      } catch (err) {
        isLoading.value = false;
        error.value = err.response.data.message;
        resetMessage();
      }
    };
    return {
      form,
      isLoading,
      error,
      success,
      thumbnailUrl,
      fileInput,
      categories,
      subCategories,
      triggerFileInput,
      handleCategory,
      handleThumbnail,
      handleFormInput,
      handleSubmit,
    };
  },
};
</script>
