import { createRouter, createWebHistory } from "vue-router";

import { isAuthenticated } from "./lib/auth";

import HomePage from "./pages/HomePage.vue";
import MenuPage from "./pages/MenuPage.vue";
import AboutUs from "./pages/AboutUs.vue";
import JobsPage from "./pages/JobsPage.vue";
import ContactPage from "./pages/ContactPage.vue";

import BookTable from "./components/BookTable.vue";

import AdminLogin from "./pages/admin/AdminLogin.vue";
import AddCulinary from "./pages/admin/AddCulinary.vue";
import CulinaryList from "./pages/admin/CulinaryList.vue";
import AddImage from "./pages/admin/AddImage.vue";
import ReservationList from "./pages/admin/ReservationList.vue";
import AddJob from "./pages/admin/AddJob.vue";
import JobRequestList from "./pages/admin/JobRequestList.vue";
import CustomerFeedback from "./pages/admin/CustomerFeedback.vue";
import AdminAboutus from "./pages/admin/AdminAboutus.vue";
import MenuHeaderImage from "./pages/admin/MenuHeaderImage.vue";
import instance from "./lib/api/clientInstance";
import SongPage from "./pages/SongPage.vue";

const clientRoutes = [
  { path: "/", component: HomePage, meta: { title: "Home" } },
  { path: "/menu/:gate", component: MenuPage, meta: { title: "Menu" } },
  {
    path: "/menu/billiard",
    component: MenuPage,
    meta: { title: "Billiard Club" },
  },
  {
    path: "/book-table",
    component: BookTable,
    meta: { title: "Reservations" },
  },
  { path: "/jobs", component: JobsPage, meta: { title: "Ажлын байр" } },
  { path: "/about", component: AboutUs, meta: { title: "Бидний тухай" } },
  { path: "/contact", component: ContactPage, meta: { title: "Холбоо барих" } },
  { path: "/karaoke", component: SongPage, meta: { title: "Дууны жагсаалт" } },
];

const adminRoutes = [
  {
    path: "/admin/login",
    component: AdminLogin,
    meta: { title: "Админ - Нэвтрэх" },
  },
  {
    path: "/admin/add-culinary",
    component: AddCulinary,
    meta: { title: "Админ - Бүтээгдэхүүн нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/culinary-list",
    component: CulinaryList,
    meta: { title: "Админ - Бүтээгдэхүүн жагсаалт", requiresAuth: true },
  },
  {
    path: "/admin/add-image",
    component: AddImage,
    meta: { title: "Админ - Зураг оруулах", requiresAuth: true },
  },
  {
    path: "/admin/reservation-list",
    component: ReservationList,
    meta: { title: "Админ - Ширээ захиалга", requiresAuth: true },
  },
  {
    path: "/admin/add-job",
    component: AddJob,
    meta: { title: "Админ - Ажлын байр нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/add-job/:id",
    component: AddJob,
    meta: { title: "Админ - Ажлын байр нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/job-request-list",
    component: JobRequestList,
    meta: { title: "Админ - Ажлын байр хүсэлт", requiresAuth: true },
  },
  {
    path: "/admin/customer-feedback",
    component: CustomerFeedback,
    meta: { title: "Админ - Санал, хүсэлт", requiresAuth: true },
  },
  {
    path: "/admin/about-us",
    component: AdminAboutus,
    meta: { title: "Админ - Бидний тухай", requiresAuth: true },
  },
  {
    path: "/admin/menu-header-image",
    component: MenuHeaderImage,
    meta: { title: "Админ - Menu Header", requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [...clientRoutes, ...adminRoutes],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

const sendVisitingInfo = async () => {
  try {
    await instance.post("/web-traffic", {
      created_at: new Date(),
    });
  } catch (error) {
    console.error("Error saving visiting info:", error);
  }
};

router.beforeEach(async (to, from, next) => {
  document.title = `GATE | ${to.meta.title}` || "Gate - Concept Bar";

  if (to.path === "/admin" || to.path === "/admin/") {
    next("/admin/login");
    return;
  }

  if (!to.path.startsWith("/admin")) {
    await sendVisitingInfo();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authenticated = await isAuthenticated();
    if (authenticated) {
      if (to.path === "/admin/login") {
        next("/admin/reservation-list");
        return;
      }
      next();
      return;
    }

    next("/admin/login");
  } else {
    if (to.path === "/admin/login") {
      const authenticated = await isAuthenticated();
      if (authenticated) {
        next("/admin/reservation-list");
        return;
      }
    }
    next();
  }
});

export default router;
