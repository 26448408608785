<template>
  <div class="bg-black">
    <HomeNavbar />

    <main class="flex justify-center items-center w-full sm:py-10 max-sm:p-3">
      <div class="bg-white rounded-lg w-full sm:w-2/3 sm:h-3/4 p-3 sm:p-5">
        <div
          class="flex items-center gap-2 p-2 rounded border text-gray-400 mb-3"
        >
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="text-sm"
          />
          <input
            id="songName"
            name="songName"
            type="text"
            placeholder="Дууны нэрээр хайх"
            class="w-full p-1 placeholder:text-sm"
            v-model="songName"
            @input="toUppercase('songName')"
          />
        </div>

        <div
          class="flex items-center gap-2 p-2 rounded border text-gray-400 mb-5"
        >
          <font-awesome-icon
            icon="fa-solid fa-magnifying-glass"
            class="text-sm"
          />
          <input
            id="singer"
            name="singer"
            type="text"
            placeholder="Хамтлаг, дуучнаар хайх"
            class="w-full p-1 placeholder:text-sm"
            v-model="singer"
            @input="toUppercase('singer')"
          />
        </div>

        <div
          v-if="songList.length > 0 && !isLoading"
          class="overflow-y-auto h-[50vh]"
        >
          <table class="border-collapse w-full">
            <thead>
              <tr class="uppercase text-center text-[#cdaf7b] max-sm:text-sm">
                <th class="border border-gray-100 p-2">Дууны нэрс</th>
                <th class="border border-gray-100 p-2">Дууны код</th>
                <th class="border border-gray-100 p-2">Хамтлаг, дуучид</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(song, index) in songList"
                :key="index"
                class="text-xs sm:text-sm"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-100'"
              >
                <td class="border border-gray-100 text-left p-2">
                  {{ song.song_name }}
                </td>
                <td class="border border-gray-100 text-center p-2">
                  {{ song.song_code }}
                </td>
                <td class="border border-gray-100 text-left p-2">
                  {{ song.singer }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-else-if="songList.length === 0 && !isLoading"
          class="flex justify-center items-center h-[40vh] text-sm"
        >
          Хайсан дуу олдсонгүй.
        </div>

        <div v-else class="flex justify-center items-center h-[50vh] text-sm">
          <svg
            aria-hidden="true"
            :class="`w-7 h-7 animate-spin`"
            viewBox="0 0 100 101"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#f7c104"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="rgb(226 232 240)"
            />
          </svg>
        </div>
      </div>
    </main>
  </div>
  <TheFooter />
</template>

<script>
import { ref, onMounted, watch } from "vue";
import instance from "@/lib/api/clientInstance";
import HomeNavbar from "../components/ui/HomeNavbar";
import TheFooter from "../components/ui/TheFooter";

export default {
  name: "SongPage",
  components: {
    HomeNavbar,
    TheFooter,
  },
  setup() {
    const songName = ref("");
    const singer = ref("");
    const isLoading = ref(false);
    const songList = ref([]);

    const fetchSongs = async () => {
      try {
        isLoading.value = true;
        const res = await instance.get(
          `/song?songName=${songName.value}&singer=${singer.value}`
        );
        if (res.status === 200) {
          songList.value = res.data.songs;
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const fetchFirstSongs = async () => {
      try {
        isLoading.value = true;
        const res = await instance.get("/songs");
        if (res.status === 200) songList.value = res.data.songs;
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    const handleInputChange = () => {
      if (!songName.value && !singer.value) {
        fetchFirstSongs();
      } else {
        fetchSongs();
      }
    };

    watch(songName, (newValue) => {
      if (newValue) singer.value = null;
      handleInputChange();
    });

    watch(singer, (newValue) => {
      if (newValue) songName.value = null;
      handleInputChange();
    });

    const toUppercase = (field) => {
      if (field === "songName") {
        songName.value = songName.value.toUpperCase();
      } else if (field === "singer") {
        singer.value = singer.value.toUpperCase();
      }
    };

    onMounted(async () => {
      await fetchFirstSongs();
    });

    return {
      songName,
      singer,
      songList,
      isLoading,
      fetchSongs,
      fetchFirstSongs,
      toUppercase,
    };
  },
};
</script>
