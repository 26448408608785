<template>
  <div @click="openModal" class="flex w-full h-[7rem] lg:h-[10rem] text-white">
    <div v-if="menu.image" class="w-3/4 md:w-3/5 h-full">
      <img
        :src="menu?.image"
        alt="menu.image"
        class="w-full h-full rounded object-center"
        :class="
          menu.root_category_id === 39 ||
          menu.root_category_id === 90 ||
          menu.root_category_id === 128
            ? 'object-contain'
            : 'object-cover'
        "
      />
    </div>
    <div v-else class="w-[12rem] h-full bg- rounded">
      <p class="text-xs text-center">No image available</p>
    </div>
    <div class="w-full ml-3 max-sm:my-1 h-full">
      <h1 class="font-bold text-lg lg:text-xl leading-5 line-clamp-2">
        {{ menu.name }}
      </h1>
      <p
        class="text-sm lg:text-base text-gray-500 font-light my-1 xl:my-3 line-clamp-2 lg:line-clamp-3 sm:leading-5"
      >
        {{ menu.description }}
      </p>
      <h4 class="text-sm lg:text-base text-white font-bold pb-5">
        {{ `${formattedPrice} ₮` }}
      </h4>
    </div>
  </div>

  <ModalDetail :menuItem="menu" :isOpen="isModalOpen" @on-close="closeModal" />
</template>

<script>
import { ref, computed } from "vue";
import ModalDetail from "../ModalDetail.vue";

export default {
  props: {
    menu: Object,
    categories: Object,
  },
  components: {
    ModalDetail,
  },
  setup(props) {
    const isModalOpen = ref(false);

    const openModal = () => {
      isModalOpen.value = true;
    };

    const closeModal = (data) => {
      if (!data) {
        isModalOpen.value = false;
      }
    };

    const formattedPrice = computed(() => {
      if (!props.menu.price) return "";
      let priceString = props.menu.price.toString();
      const parts = [];

      while (priceString.length > 3) {
        parts.unshift(priceString.slice(-3));
        priceString = priceString.slice(0, -3);
      }
      parts.unshift(priceString);
      return parts.join("'");
    });

    return {
      isModalOpen,
      openModal,
      closeModal,
      formattedPrice,
    };
  },
};
</script>
