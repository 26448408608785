<template>
  <admin-container>
    <h1 class="text-3xl font-bold mb-8">Цэс жагсаалт</h1>
    <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-8">
      <div class="col-span-1 md:col-span-2">
        <label for="branch" class="block mb-2 text-sm font-medium text-gray-900"
          >Салбар сонгоно уу:</label
        >
        <select
          name="branch"
          id="branch"
          v-model="params.branch"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option value="" selected disabled>сонгох</option>
          <option value="1">Gate 4</option>
          <option value="2">Gate 5</option>
          <option value="3">Gate 6</option>
          <option value="4">Gate 7</option>
          <option value="5">Billiard</option>
          <option value="6">Karaoke</option>
        </select>
      </div>
      <!-- category filter -->
      <div v-if="params.branch" class="col-span-1 md:col-span-2">
        <label
          for="category"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Категори шүүх
        </label>
        <select
          name="category"
          id="category"
          v-model="params.category"
          @change="handleCategory"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option value="" selected disabled>сонгох</option>
          <option
            v-for="cat in categories"
            :value="cat.id"
            :key="cat.id"
            class="uppercase"
          >
            {{ cat.name }}
          </option>
        </select>
      </div>
      <!-- sub category filter -->
      <div v-if="params.category" class="col-span-1 md:col-span-2">
        <label
          for="subCategory"
          class="block mb-2 text-sm font-medium text-gray-900"
        >
          Дэд категори шүүх
        </label>
        <select
          name="subCategory"
          id="subCategory"
          v-model="params.subCategory"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option value="" selected disabled>сонгох</option>
          <option
            v-for="subCat in subCategories"
            :value="subCat.id"
            :key="subCat.id"
            class="uppercase"
          >
            {{ subCat.name }}
          </option>
        </select>
      </div>
    </div>
    <hot-table
      ref="hotRef"
      v-if="culinaryData && culinaryData.length > 0 && !isLoading"
      :settings="settings"
      :data="culinaryData"
    ></hot-table>
    <!-- <div v-else-if="isLoading" class="flex justify-center items-center h-96">
      <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-blue-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div> -->
    <div
      class="flex flex-col items-center justify-center"
      v-else-if="culinaryData && culinaryData.length === 0 && !isLoading"
    >
      <h1 class="text-2xl font-bold mb-4">Цэс олдсонгүй</h1>
    </div>

    <error-modal v-if="error" :error="error" />
    <success-modal v-if="success" :success="success" />
  </admin-container>
</template>

<script>
import { ref, reactive, watch, onMounted } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";

import adminInstance from "@/lib/api/adminInstance";

registerAllModules();
export default {
  name: "CulinaryList",
  components: {
    HotTable,
    ErrorModal,
    SuccessModal,
  },
  setup() {
    const params = reactive({
      branch: "1",
      category: "",
      subCategory: "",
    });

    const categories = ref([]);
    const subCategories = ref([]);
    const culinaryData = ref([]);
    const hotRef = ref(null);

    const selectedRowCategory = ref(null);

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    onMounted(() => {
      fetchCategories();
      fetchCulinaryList();
    });

    watch([() => params.category, () => params.subCategory], () => {
      fetchCulinaryList();
    });

    watch(
      () => params.branch,
      () => {
        params.category = "";
        params.subCategory = "";
        fetchCategories();
        fetchCulinaryList();
      }
    );

    const fetchCulinaryList = async () => {
      try {
        isLoading.value = true;
        const query = new URLSearchParams(params).toString();
        const response = await adminInstance.get(`/culinary?${query}`);
        if (response && response.status === 200 && response.data.culinary) {
          culinaryData.value = response.data.culinary;
          if (hotRef.value && hotRef.value.hotInstance) {
            hotRef.value.hotInstance.updateSettings({
              data: culinaryData.value,
            });
          }
        } else {
          error.value = "Алдаа гарлаа!";
          resetMessage();
        }
      } catch (e) {
        console.error(e);
        if (e && e.response && e.response.data) {
          error.value = e.response.data.message;
        } else {
          error.value = "Алдаа гарлаа!";
        }
        resetMessage();
      } finally {
        isLoading.value = false;
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await adminInstance.get("/category");
        if (response && response.status === 200) {
          categories.value = response.data.category.filter(
            (cat) => cat.branch_id === parseInt(params.branch)
          );
        } else {
          error.value = "Алдаа гарлаа!";
          resetMessage();
        }
      } catch (e) {
        console.error(e);
        if (e && e.response && e.response.data) {
          error.value = e.response.data.message;
        } else {
          error.value = "Алдаа гарлаа!";
        }
        resetMessage();
      }
    };

    const handleCategory = (e) => {
      params.category = e.target.value;

      subCategories.value =
        categories.value.filter(
          (cat) => cat.id === parseInt(params.category)
        )[0]?.subCategory || [];
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    /*
      Event handlers
    */
    const handleImageDelete = (row) => {
      culinaryData.value[row].image = "";
      hotRef.value.hotInstance.render();
    };

    const handleDeleteRow = async (row) => {
      try {
        const id = culinaryData.value[row].id;
        const response = await adminInstance.delete(`/culinary/${id}`);
        if (response.status === 200) {
          culinaryData.value.splice(row, 1);
          // hotRef.value.hotInstance.render();
          success.value = response.data.message;
        }
      } catch (e) {
        error.value = e.response.data.message;
      } finally {
        resetMessage();
      }
    };

    const updateRow = async (row) => {
      const formData = new FormData();
      const rowData = culinaryData.value[row];

      const valid = Object.keys(rowData).every((key) => {
        if (key === "imageFile") return true;
        return rowData[key];
      });

      // // if row data is equal to initial data, do not send request
      // if (JSON.stringify(rowData) === JSON.stringify(culinaryData.value[row])) {
      //   // error.value = "Өөрчлөлт оруулаагүй байна";
      //   return;
      // }

      if (!valid) {
        error.value = `${row + 1} дахь мөрний бүх талбарыг бөглөнө үү`;
        resetMessage();
        return;
      }

      let category = selectedRowCategory.value || rowData.category_id;
      formData.append("name", rowData.name);
      formData.append("category", category);
      formData.append("price", rowData.price);
      formData.append("description", rowData.description);
      formData.append("image", rowData.imageFile);
      formData.append("imageUri", rowData.image);
      formData.append("branch", rowData.branch_id);
      try {
        const response = await adminInstance.put(
          `/culinary/${rowData.id}`,
          formData
        );
        if (response.status === 200) {
          success.value = response.data.message;
        }
      } catch (e) {
        error.value = e.response.data.message;
      } finally {
        resetMessage();
      }
    };

    const handleFileUpload = (row) => {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          // You might want to show a preview or store the file for later submission
          culinaryData.value[row].imageFile = file;
          culinaryData.value[row].image = URL.createObjectURL(file);
          hotRef.value.hotInstance.render();
        }
      };
      input.click();
    };

    /*
      renderer functions
    */
    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.innerHTML = `<span class="text-[#222222] font-semibold text-center">${value}</span>`;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      const deleteButtonId = `image-delete-button-${row}`;
      const uploadButtonId = `image-upload-button-${row}`;

      td.innerHTML = value
        ? `<div class="relative flex items-center justify-center py-2">
            <img src="${value}" alt="" class="h-20 w-20 object-fit" />
            <button id="${deleteButtonId}" class="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center">X</button>
          </div>`
        : `<div class="flex justify-center items-center py-4">
            <button id="${uploadButtonId}" class="bg-blue-500 text-white rounded-md text-center p-2">Зураг оруулах</button>
          </div>`;

      // image delete button
      const deleteButton = document.getElementById(deleteButtonId);
      if (deleteButton) {
        deleteButton.onclick = () => handleImageDelete(row);
      }

      // image upload button
      const uploadButton = document.getElementById(uploadButtonId);
      if (uploadButton) {
        uploadButton.onclick = () => handleFileUpload(row);
      }
    };

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const editButtonId = `edit-button-${row}`;
      const deleteButtonId = `delete-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2">
        <button id="${editButtonId}" class="bg-blue-500 text-white rounded-md text-center p-2 hover:bg-blue-700 transition ease-in-out duration-150">Засах</button>
        <button id="${deleteButtonId}" class="bg-red-500 text-white rounded-md text-center p-2">Устгах</button>
      </div>`;

      // row edit button
      const editButton = document.getElementById(editButtonId);
      if (editButton) {
        editButton.onclick = () => updateRow(row);
      }

      // row delete button
      const deleteButton = document.getElementById(deleteButtonId);

      if (deleteButton) {
        deleteButton.onclick = () => handleDeleteRow(row);
      }
    };

    const handleRowCategory = (e) => {
      selectedRowCategory.value = e.target.value;
    };

    const selectOptionRenderer = (instance, td, row, col, prop, value) => {
      // Fetching the category for the current row
      const category = hotRef.value.hotInstance.getDataAtRowProp(
        row,
        "selfCategory"
      );
      value = value.filter((cat) => cat.id !== category.id);
      // Generating the select element HTML string
      const selectHTML = `
    <select name="category" class="category-select mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300">
      <option value="${category ? category.id : ""}"  selected>${
        category ? category.name : "сонгох"
      }</option>
      ${value
        .map((cat) => `<option value="${cat.id}">${cat.name}</option>`)
        .join("")}
    </select>
  `;

      // Setting the innerHTML of the table cell
      td.innerHTML = selectHTML;
      // Attach the event listener to the select element
      const selectElement = td.querySelector(".category-select");
      selectElement.addEventListener("change", handleRowCategory);
    };

    /*
      handsontable settings
    */
    const settings = ref({
      colHeaders: ["Нэр", "Төрөл", "Үнэ(₮)", "Тайлбар", "Зураг", "Үйлдэл"],
      columns: [
        {
          data: "name",
          type: "text",
          renderer: commonRenderer,
          width: 200,
        },
        {
          data: "categoriesSource",
          readOnly: true,
          type: "dropdown",
          renderer: selectOptionRenderer,
          width: 150,
        },
        {
          data: "price",
          type: "numeric",
          renderer: commonRenderer,
          width: 100,
        },
        {
          data: "description",
          type: "text",
          renderer: commonRenderer,
          width: 350,
        },
        {
          data: "image",
          type: "text",
          readOnly: true,
          renderer: imageRenderer,
          width: 120,
        },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      data: culinaryData.value,
      stretchH: "all",
      rowHeaders: true,
      width: "100%",
      height: 500,
      licenseKey: "non-commercial-and-evaluation",
    });

    return {
      params,
      categories,
      subCategories,
      error,
      success,
      isLoading,
      hotRef,
      settings,
      culinaryData,
      handleCategory,
    };
  },
};
</script>
