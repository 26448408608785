<template>
  <div class="relative flex flex-col md:flex-row h-screen font-sans">
    <div class="bg-brand text-white w-64 hidden md:flex flex-col">
      <div class="p-4 flex items-center gap-6 border-b border-borderColor">
        <img src="../../assets/logo.jpg" alt="" class="w-12 h-12" />
        <span class="text-lg font-bold capitalize">Gate admin</span>
      </div>
      <nav class="flex-1">
        <ul class="p-4">
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-culinary') }"
              to="/admin/add-culinary"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Цэс нэмэх
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/culinary-list') }"
              to="/admin/culinary-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Цэс жагсаалт
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-image') }"
              to="/admin/add-image"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Зураг оруулах
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/reservation-list') }"
              to="/admin/reservation-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ширээ захиалга
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-job') }"
              to="/admin/add-job"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ажил оруулах
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/job-request-list') }"
              to="/admin/job-request-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ажлын хүсэлт, зар
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/customer-feedback') }"
              to="/admin/customer-feedback"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Хандалт, санал хүсэлт
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/about-us') }"
              to="/admin/about-us"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Бидний тухай
            </router-link>
          </li>
          <li class="mb-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/menu-header-image') }"
              to="/admin/menu-header-image"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Меню header зураг
            </router-link>
          </li>

          <li class="mb-4">
            <button
              class="flex items-center hover:text-yellow uppercase text-sm"
              @click="logout"
            >
              Гарах
            </button>
          </li>
        </ul>
      </nav>
      <div class="flex flex-col justify-end items-center h-full p-4">
        <div>Өнөөдрийн хандалт:</div>
        <p class="font-bold text-2xl mb-3">{{ visitorNumber }}</p>
        <p class="text-gray-500 font-thin">{{ date }}</p>
      </div>
    </div>

    <!-- small screen nav -->
    <div class="bg-brand w-full block md:hidden">
      <div
        class="p-2 h-16 flex items-center justify-between gap-6 border-b border-borderColor"
      >
        <button @click="toggleNav">
          <font-awesome-icon
            icon="fa-solid fa-bars"
            class="w-5 h-5 text-white"
          />
        </button>
        <span class="text-lg font-bold capitalize text-white">Gate admin</span>
      </div>
      <transition name="slide">
        <div
          v-if="isNavOpen"
          class="absolute top-0 left-0 z-[999] w-64 bg-brand h-screen text-white px-2"
        >
          <div class="h-16 flex items-center">
            <button @click="toggleNav">
              <font-awesome-icon
                icon="fa-solid fa-close"
                class="w-5 h-5 text-white"
              />
            </button>
          </div>
          <div class="grid gap-4">
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-culinary') }"
              to="/admin/add-culinary"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Цэс нэмэх
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/culinary-list') }"
              to="/admin/culinary-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Цэс жагсаалт
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-image') }"
              to="/admin/add-image"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Зураг оруулах
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/reservation-list') }"
              to="/admin/reservation-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ширээ захиалга
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/add-job') }"
              to="/admin/add-job"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ажил оруулах
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/job-request-list') }"
              to="/admin/job-request-list"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Ажлын хүсэлт, зар
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/customer-feedback') }"
              to="/admin/customer-feedback"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Хандалт, санал хүсэлт
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/about-us') }"
              to="/admin/about-us"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Бидний тухай
            </router-link>
            <router-link
              :class="{ 'text-yellow': isActive('/admin/menu-header-image') }"
              to="/admin/menu-header-image"
              class="flex items-center hover:text-yellow uppercase text-sm"
            >
              Меню header зураг
            </router-link>
            <button
              class="flex items-center hover:text-yellow uppercase text-sm"
              @click="logout"
            >
              Гарах
            </button>
            <div class="flex flex-col justify-end items-center h-full p-4 mt-5">
              <div>Өнөөдрийн хандалт:</div>
              <p class="font-bold text-xl mb-3">{{ visitorNumber }}</p>
              <p class="text-gray-500 font-thin">{{ date }}</p>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="relative bg-gray-100 flex-1 overflow-y-scroll p-4">
      <slot></slot>
    </div>

    <!-- backdrop -->
    <transition name="fade">
      <div
        v-if="isNavOpen"
        class="fixed top-0 left-0 z-[998] w-full h-full bg-black bg-opacity-50"
        @click="toggleNav"
      ></div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import adminInstance from "@/lib/api/adminInstance";

export default {
  name: "AdminContainer",
  setup() {
    const router = useRouter();
    const isNavOpen = ref(false);
    const isActive = (to) => router.currentRoute.value.path === to;

    const toggleNav = () => {
      isNavOpen.value = !isNavOpen.value;
    };

    const logout = async () => {
      try {
        const response = await adminInstance.post("/logout");
        if (response.status === 200) {
          router.push("/admin/login");
        }
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      getDate();
      await fetchWebTraffic();
    });

    const date = ref("");
    const getDate = () => {
      const today = new Date();
      date.value =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    };

    const visitorNumber = ref("");
    const fetchWebTraffic = async () => {
      const res = await adminInstance.post("/visitors", {
        created_at: date.value,
      });
      visitorNumber.value = res.data.visitors.length;
    };

    return {
      isNavOpen,
      toggleNav,
      logout,
      isActive,
      date,
      visitorNumber,
    };
  },
};
</script>

<style>
.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter-from {
  transform: translateX(-100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
