<template>
  <admin-container>
    <h1 class="text-3xl font-bold mb-8">Нүүр хуудасны зураг оруулах</h1>
    <div class="grid grid-cols-1 md:grid-cols-9 gap-4">
      <div class="col-span-1 md:col-span-2 flex flex-col">
        <label for="name" class="block mb-2 text-sm font-medium text-gray-900"
          >Зураг сонгох</label
        >
        <input
          type="file"
          name="image"
          id="image"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
          ref="fileInput"
          @change="onFileChange"
        />
        <button
          class="flex justify-center items-center bg-brand text-white p-2 rounded-md hover:opacity-90 transition duration-200 ease-in-out"
          @click="addImage"
        >
          Зураг оруулах
        </button>
      </div>

      <div class="col-span-7 relative flex flex-col gap-2" v-show="imageUrl">
        <div class="absolute top-0 right-0">
          <button
            class="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            @click="resetImage"
          >
            X
          </button>
        </div>
        <img :src="imageUrl" alt="img" class="rounded" />
      </div>
    </div>

    <error-modal :error="error" v-if="error" />
    <success-modal :success="success" v-if="success" />
  </admin-container>
</template>

<script>
import { ref } from "vue";
// import { HotTable } from "@handsontable/vue3";
// import { registerAllModules } from "handsontable/registry";
// import "handsontable/dist/handsontable.full.css";
// import "handsontable/dist/handsontable.full.min.css";

import adminInstance from "@/lib/api/adminInstance";

import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
// registerAllModules();
export default {
  name: "AddImage",
  components: {
    ErrorModal,
    SuccessModal,
    // HotTable,
  },
  setup() {
    const imageUrl = ref("");
    const imageFile = ref(null);
    const fileInput = ref(null);

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const onFileChange = (e) => {
      if (!e.target.files.length) return;
      const file = e.target.files[0];
      imageFile.value = file;
      imageUrl.value = URL.createObjectURL(file);
    };

    const resetImage = () => {
      imageUrl.value = "";
      imageFile.value = null;
      if (fileInput.value) {
        fileInput.value.value = "";
      }
    };

    const resetMessage = () => {
      let timer = setTimeout(() => {
        error.value = "";
        success.value = "";
        clearTimeout(timer);
      }, 3000);
    };

    const addImage = async () => {
      try {
        if (!imageFile.value) {
          error.value = "Зураг оруулна уу";
          return;
        }
        const formData = new FormData();
        formData.append("image", imageFile.value);

        const response = await adminInstance.post("/image", formData);
        if (response.status === 200) {
          success.value = response.data.message;
          resetImage();
        }
      } catch (e) {
        error.value = e.response.data.message;
        console.error(e);
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };
    return {
      imageUrl,
      imageFile,
      fileInput,
      error,
      success,
      onFileChange,
      resetImage,
      addImage,
    };
  },
};
</script>
