<template>
  <div class="min-h-screen bg-[#010101] font-sans">
    <transition name="slide-fade">
      <div
        v-if="isReminder"
        class="flex justify-between items-center gap-3 bg-[#f7c104] p-4"
      >
        <div class="flex items-center justify-center w-full">
          <p
            class="text-black text-md max-sm:text-xs font-bold uppercase tracking-medium text-center"
          >
            5 дахь өдрийн захиалга авахгүй тул та утсаар холбогдох эсвэл шууд
            ирээд үйлчлүүлээрэй. Баярлалаа!
          </p>
        </div>
        <button
          @click="hideReminderMessage"
          class="flex justify-center items-center rounded-full bg-white opacity-40 w-5 h-5"
        >
          <font-awesome-icon
            icon="fa-solid fa-x"
            class="w-3 h-3 max-sm:w-2 max-sm:h-2 text-black"
          />
        </button>
      </div>
    </transition>

    <div class="max-w-[768px] mx-auto p-4 pt-8">
      <div class="flex flex-col items-center gap-4 mb-8">
        <!-- <p class="text-[#ffff] text-2xl font-bold uppercase tracking-[6px]">Ширээ захиалах</p> -->
        <div
          class="flex gap-4 justify-center items-center text-[#f7c104] text-xl font-bold"
        >
          <button
            :class="{
              'border-b-2 border-[#f7c104] ': reservationType === 'regular',
            }"
            @click="handlereservationType('regular')"
          >
            REGULAR
          </button>
          <button
            :class="{
              'border-b-2 border-[#f7c104] ': reservationType === 'vip',
            }"
            @click="handlereservationType('vip')"
          >
            VIP
          </button>
        </div>
      </div>

      <!-- regular -->
      <div
        v-if="reservationType === 'regular'"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
      >
        <div class="flex flex-col gap-1">
          <!-- select branch -->
          <label for="branch" class="text-white">Салбар</label>
          <select
            name="branch"
            id="branch"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.branch"
            @change="handlereservationInputs"
          >
            <option value="" disabled selected>Салбар сонгоно уу</option>
            <option value="4">Салбар 4</option>
            <option value="5">Салбар 5</option>
            <option value="6">Салбар 6</option>
          </select>
        </div>
        <div class="flex flex-col gap-1">
          <label for="date" class="text-white">Огноо</label>
          <input
            type="date"
            name="date"
            id="date"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.date"
            :min="currentDate"
            @input="handleDateChange"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label for="time" class="text-white">Цаг</label>
          <input
            type="time"
            name="time"
            id="time"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.time"
            :min="currentTime"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label for="numberOfPeople" class="text-white">Хүний тоо</label>
          <input
            type="number"
            name="numberOfPeople"
            id="numberOfPeople"
            min="1"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.numberOfPeople"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label for="name" class="text-white">Нэр</label>
          <input
            type="text"
            name="name"
            id="name"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.name"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label for="phone" class="text-white">Дугаар</label>
          <input
            type="text"
            name="phone"
            id="phone"
            pattern="[0-9]{8}"
            maxlength="8"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.phone"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1 col-span-1 sm:col-span-2 md:col-span-3">
          <label for="email" class="text-white">И-мэйл</label>
          <input
            type="email"
            name="email"
            id="email"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.email"
            @change="handlereservationInputs"
          />
        </div>
        <div class="flex flex-col gap-1 col-span-1 sm:col-span-2 md:col-span-3">
          <label for="description" class="text-white"
            >Захиалгын дэлгэрэнгүй</label
          >
          <textarea
            name="description"
            id="description"
            cols="30"
            rows="10"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.description"
            @change="handlereservationInputs"
          ></textarea>
        </div>
        <div
          class="col-span-1 sm:col-span-2 md:col-span-3 flex justify-center mt-2"
        >
          <button
            class="w-[250px] mx-auto bg-[#f7c104] text-white p-3 rounded-md"
            :disabled="isLoading"
            @click="handleBooking()"
          >
            <span
              v-if="isLoading"
              class="flex items-center justify-center gap-2"
            >
              <svg
                class="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="white"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
              </svg>
              <span>Түр хүлээнэ үү...</span>
            </span>
            <span v-else>Захиалах</span>
          </button>
        </div>
      </div>

      <!-- vip -->
      <div v-if="reservationType === 'vip'" class="flex flex-col gap-1 mb-4">
        <div class="flex flex-col gap-1 mb-4">
          <label for="branch" class="text-white">Салбар</label>
          <select
            name="branch"
            id="branch"
            class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
            :value="reservationInputs.branch"
            @change="handlereservationInputs"
          >
            <option value="" disabled selected>Салбар сонгоно уу</option>
            <!-- <option value="4">Салбар 4</option> -->
            <option value="5">Салбар 5</option>
            <option value="6">Салбар 6</option>
          </select>
        </div>
        <div v-if="currentVipRoom">
          <p class="text-white mb-2">Өрөө сонгох</p>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <div
              class="relative flex flex-col gap-2 bg-[#353437] p-4 rounded-md cursor-pointer hover:scale-105 transition duration-300 ease-in-out"
              :class="{ 'border-2 border-[#f7c104]': selectedVipRoom === room }"
              v-for="room in currentVipRoom"
              :key="room.id"
              @click="handleSelectedVipRoom(room)"
            >
              <!-- id number indication small circle top right corner -->
              <div
                class="absolute top-2 right-2 flex justify-center items-center bg-[#f7c104] text-white w-4 h-4 p-4 rounded-full text-md"
              >
                {{ room?.id }}
              </div>
              <img
                :src="room?.image"
                alt="vip-room"
                class="w-full h-[200px] object-cover rounded-md"
              />
              <div class="flex flex-col gap-1">
                <p class="text-white font-bold">
                  Хүний багтаамж:
                  <span class="text-accent font-normal">{{
                    room?.capacity
                  }}</span>
                </p>
                <p class="text-white font-bold">
                  1 цагийн:
                  <span class="text-accent font-normal"
                    >{{ room?.price }}₮</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div class="flex flex-col gap-1">
            <label for="date" class="text-white">Огноо</label>
            <input
              type="date"
              name="date"
              id="date"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.date"
              :min="currentDate"
              @input="handleDateChange"
              @change="handlereservationInputs"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label for="time" class="text-white">Цаг</label>
            <input
              type="time"
              name="time"
              id="time"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.time"
              :min="currentTime"
              @change="handlereservationInputs"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label for="numberOfPeople" class="text-white">Хүний тоо</label>
            <input
              type="number"
              name="numberOfPeople"
              id="numberOfPeople"
              min="1"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.numberOfPeople"
              @change="handlereservationInputs"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label for="name" class="text-white">Нэр</label>
            <input
              type="text"
              name="name"
              id="name"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.name"
              @change="handlereservationInputs"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label for="phone" class="text-white">Дугаар</label>
            <input
              type="text"
              name="phone"
              id="phone"
              pattern="[0-9]{8}"
              maxlength="8"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.phone"
              @change="handlereservationInputs"
            />
          </div>
          <div class="flex flex-col gap-1">
            <label for="email" class="text-white">И-мэйл</label>
            <input
              type="email"
              name="email"
              id="email"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.email"
              @change="handlereservationInputs"
            />
          </div>
          <div
            class="flex flex-col gap-1 col-span-1 sm:col-span-2 md:col-span-3"
          >
            <label for="description" class="text-white"
              >Захиалгын дэлгэрэнгүй</label
            >
            <textarea
              name="description"
              id="description"
              cols="30"
              rows="10"
              class="p-3 bg-[#353437] w-full text-white custom-date-input rounded-md"
              :value="reservationInputs.description"
              @change="handlereservationInputs"
            ></textarea>
          </div>
          <div
            class="col-span-1 sm:col-span-2 md:col-span-3 flex justify-center mt-2"
          >
            <button
              class="w-[250px] mx-auto bg-[#f7c104] text-white p-3 rounded-md"
              :disabled="isLoading"
              @click="handleBooking()"
            >
              <span
                v-if="isLoading"
                class="flex items-center justify-center gap-2"
              >
                <svg
                  class="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="white"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                <span>Түр хүлээнэ үү...</span>
              </span>
              <span v-else>Захиалах</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div
        v-if="error"
        class="fixed top-0 right-2 r flex items-center justify-center px-4 py-6 pointer-events-none"
      >
        <div
          class="max-w-sm w-full bg-red-500 text-white p-4 rounded-lg shadow-lg text-sm flex items-center space-x-2"
        >
          <span>{{ error }}</span>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="success"
        class="fixed top-0 right-2 r flex items-center justify-center px-4 py-6 pointer-events-none"
      >
        <div
          class="max-w-sm w-full bg-green-500 text-white p-4 rounded-lg shadow-lg text-sm flex items-center space-x-2"
        >
          <span>{{ success }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import instance from "@/lib/api/clientInstance";
import { vipRooms } from "@/lib/constants";
export default {
  name: "BookTable",

  setup() {
    const reservationType = ref("regular");
    const reservationInputs = reactive({
      reservationType: "",
      branch: "",
      date: "",
      time: "",
      numberOfPeople: "",
      name: "",
      phone: "",
      email: "",
      description: "",
    });

    const currentDate = ref("");
    const currentTime = ref("");

    const error = ref("");
    const success = ref("");
    const isLoading = ref(false);

    const currentVipRoom = ref(null);
    const selectedVipRoom = ref(null);

    onMounted(() => {
      const now = new Date();
      currentDate.value = now.toISOString().split("T")[0];
      currentTime.value = now.toTimeString().split(" ")[0].slice(0, 5);
    });

    watch(reservationType, () => {
      resetreservationInputs();
    });

    watch(
      () => reservationInputs.branch,
      (newVal) => {
        if (reservationType.value === "vip") {
          if (newVal === "4") {
            currentVipRoom.value = vipRooms.gateFour;
          } else if (newVal === "5") {
            currentVipRoom.value = vipRooms.gateFive;
          } else if (newVal === "6") {
            currentVipRoom.value = vipRooms.gateSix;
          }
        }
      }
    );

    // switch between regular and vip
    const handlereservationType = (val) => {
      if (reservationType.value === val) return;
      reservationType.value = val;
    };

    // handle booking inputs
    const handlereservationInputs = (e) => {
      reservationInputs[e.target.name] = e.target.value;
    };

    // handle selected vip room
    const handleSelectedVipRoom = (room) => {
      selectedVipRoom.value = room;
    };

    // reset booking inputs
    const resetreservationInputs = () => {
      reservationInputs.reservationType = "";
      reservationInputs.branch = "";
      reservationInputs.date = "";
      reservationInputs.time = "";
      reservationInputs.numberOfPeople = "";
      reservationInputs.name = "";
      reservationInputs.phone = "";
      reservationInputs.email = "";
      reservationInputs.description = "";
      // reset vip room
      currentVipRoom.value = null;
      selectedVipRoom.value = null;
    };

    // reset message
    const resetMessage = () => {
      setTimeout(() => {
        error.value = "";
        success.value = "";
      }, 5000);
    };

    // validate booking inputs
    const validateEmail = (email) => {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    };

    const validatePhone = (phone) => {
      const re = /^\d{8}$/;
      return re.test(phone);
    };

    const checkCapacity = () => {
      if (reservationType.value === "vip" && selectedVipRoom.value) {
        const maxCapacity = selectedVipRoom.value.capacity
          .split("-")
          .map(Number)[1];
        const capacityExceeded = reservationInputs.numberOfPeople > maxCapacity;
        if (capacityExceeded) {
          error.value = `Өрөөний хүний багтаамж ${maxCapacity}`;
          isLoading.value = false;
          resetMessage();
          return false;
        }
      }
      return true;
    };

    const validatereservationInputs = () => {
      if (reservationType.value === "vip" && !selectedVipRoom.value) {
        error.value = "Өрөө сонгоно уу";
        isLoading.value = false;
        resetMessage();
        return false;
      }

      if (isFridaySelected.value) {
        error.value =
          "Уучлаарай, 5 дахь өдөр захиалга өгөх боломжгүй байна. Та бусад өдрөөс сонгоно уу.";
        isLoading.value = false;
        resetMessage();
        return false;
      }

      if (!checkCapacity()) return false;

      const fields = [
        { key: "branch", message: "Салбар сонгоно уу" },
        { key: "date", message: "Огноо сонгоно уу" },
        { key: "time", message: "Цаг сонгоно уу" },
        { key: "numberOfPeople", message: "Хүний тоо оруулна уу" },
        { key: "name", message: "Нэр оруулна уу" },
        { key: "phone", message: "Утасны дугаар оруулна уу" },
        { key: "email", message: "И-мэйл оруулна уу" },
      ];

      // validate time between 12pm to 12am
      for (let i = 0; i < fields.length; i++) {
        if (!reservationInputs[fields[i].key]) {
          error.value = fields[i].message;
          isLoading.value = false;
          resetMessage();
          return false;
        }
        if (fields[i].key === "time") {
          const time = reservationInputs.time.split(":");
          if (time[0] < 12 || time[0] > 23) {
            error.value = "Цаг 12:00 - 23:00 хооронд захиална уу";
            isLoading.value = false;
            resetMessage();
            return false;
          }
        }

        if (fields[i].key === "phone") {
          if (!validatePhone(reservationInputs[fields[i].key])) {
            error.value = "Утасны дугаар буруу байна";
            isLoading.value = false;
            resetMessage();
            return false;
          }
        }
        if (fields[i].key === "email") {
          if (!validateEmail(reservationInputs[fields[i].key])) {
            error.value = "И-мэйл хаяг буруу байна";
            isLoading.value = false;
            resetMessage();
            return false;
          }
        }
      }

      return true;
    };

    /*
      handle booking send to server
    */
    const handleBooking = async () => {
      isLoading.value = true;
      // const booked = document.cookie.split(";").some((item) => item.trim().startsWith("booked="));
      // if (booked) {
      //   error.value = "Цагт 1 удаа захиалга хийх боломжтой";
      //   isLoading.value = false;
      //   resetMessage();
      //   return;
      // }

      if (!validatereservationInputs()) return;

      if (reservationType.value === "vip") {
        reservationInputs.reservationType = "vip";
        reservationInputs.vipRoom = selectedVipRoom.value?.id;
      } else {
        reservationInputs.reservationType = "regular";
      }

      try {
        const response = await instance.post("/reservation", reservationInputs);
        if (response.status === 200) {
          success.value = response?.data.message;
          document.cookie = "booked=true;max-age=3600";
          resetreservationInputs();
        }
      } catch (e) {
        error.value = e.response.data.message;
        console.error(e);
      } finally {
        isLoading.value = false;
        resetMessage();
      }
    };

    //Хуудасны эхний сануулга мессежийг hide хийх
    const isReminder = ref(true);
    const hideReminderMessage = () => {
      isReminder.value = !isReminder.value;
    };

    //5 дахь өдрийг сонгосон эсэхийг шалгах function
    const isFridaySelected = ref(false);
    // const handleDateChange = (event) => {
    //   const selectedDate = new Date(event.target.value);

    //   isFridaySelected.value = selectedDate.getDay() === 5;
    //   if (!isFridaySelected.value) {
    //     reservationInputs.value.date = event.target.value;
    //   }
    // };

    const handleDateChange = (event) => {
      const selectedDate = new Date(event.target.value);
      if (selectedDate.getDay() === 5) {
        isFridaySelected.value = true;
        error.value =
          "Уучлаарай, 5 дахь өдөр захиалга өгөх боломжгүй байна. Та бусад өдрөөс сонгоно уу.";
        resetMessage();
      } else {
        isFridaySelected.value = false;
        reservationInputs.date = event.target.value;
      }
    };

    return {
      error,
      success,
      isLoading,
      currentDate,
      currentTime,
      reservationType,
      reservationInputs,
      currentVipRoom,
      selectedVipRoom,
      handleSelectedVipRoom,
      handlereservationType,
      handlereservationInputs,
      handleBooking,
      isReminder,
      hideReminderMessage,
      handleDateChange,
    };
  },
};
</script>

<style scoped>
.custom-date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
